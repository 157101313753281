<template>
  <div class="pb-5">
    <v-col cols="12" class="py-0">
      <v-toolbar class="mt-n3" color="transparent" flat height="45">
        <v-spacer />
        <v-toolbar-items>
          <v-row class="mr-n2" align="center" justify="end" no-gutters>
            <template v-if="$helpers.hasSomePermission(['view_member', 'view_invitation'])">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
                    <v-icon left>mdi-export-variant</v-icon>Exportar<v-icon size="18" right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="createExport('members')" :ripple="false">
                    <v-list-item-title class="body-2">Usuarios</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-3" @click="createExport('invitations')" :ripple="false">
                    <v-list-item-title class="body-2">Invitaciones pendientes</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-query-builder class="mx-2" :query-map="filters" model="member" />
            </template>
            <v-btn v-if="$helpers.hasPermission(['add_invitation'])" @click="dialogCreate=true" color="blue-500"><v-icon left>mdi-account-plus</v-icon>Invitar usuario</v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-toolbar-title v-if="activeMembersList.length && ['Active', null].includes((($route || {}).query || {}).status || null)" class="subtitle-1 grey-700--text font-weight-bold">Usuarios activos</v-toolbar-title>
    </v-col>
    <template v-if="!$store.state.accounts.LoaderMe">
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_member', 'view_invitation'])" />
      <template v-else>
        <!-- active -->
        <skeleton-list v-if="loadingActiveMembers" />
        <template v-else-if="['Active', null].includes((($route || {}).query || {}).status || null)">
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col v-for="(d, index) in activeMembersList" :key="`${d.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                <v-hover v-slot="{ hover }">
                  <v-card class="pa-5 rounded-md" :elevation="$helpers.hasPermission(['change_member']) && hover ? 5 : 0" flat  @click="$router.replace({ name: 'AccountMemberUpdate', params: { id: d.id, ...currentParams }})" :style="`cursor:${$helpers.hasPermission(['change_member']) ? 'pointer' :'default'}`">
                    <v-row no-gutters>
                      <v-tooltip color="grey-background" right width="160px" :open-on-hover="d.is_owner" :open-on-click="false">
                        <template v-slot:activator="{on}">
                        <v-icon class="mb-1" color="blue-500" v-on="on">mdi-account{{ !d.is_owner ? '' : '-star' }}</v-icon>
                        </template>
                        <span class="d-block px-3 py-2">Dueño del espacio</span>
                      </v-tooltip>
                      <v-spacer />
                      <v-menu v-model="menuActiveMember[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="25" transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                            <v-icon color="grey-700" size="24">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item class="px-3" @click="$router.replace({ name: 'AccountMemberUpdate', params: { id: d.id, ...currentParams } })" :ripple="false">
                            <v-list-item-title class="body-2">Ver información del usuario</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                    <span class="d-block">
                      <v-sheet class="transparent" height="25">
                        <span v-if="d.first_name.length && d.first_name !== ' '" class="grey-700--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 99%;">
                          <span class="text-capitalize">{{d.first_name | lower}} {{d.last_name | lower}}</span>
                        </span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </v-sheet>
                    </span>
                    <span v-if="d.email.length" class="mt-n2 d-block body-2 grey-300--text d-inline-block text-truncate" style="max-width: 99%; line-height: 22px;">{{d.email}}</span>
                    <span class="d-block mt-n1 body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <VMainPagination v-if="(showPagination || showAll) && activeMembersList.length" :count="activeMembersList?.length" :currentPage="currentPageActive" @isActive="setQueryMembers($event, 'Active')" />
          </v-col>
        </template>
        <!-- end active -->

        <!-- pendients -->
        <skeleton-list v-if="loadingInvitations" />
        <template v-else-if="['Accepted', null].includes((($route || {}).query || {}).status || null)">
          <template v-if="invitationsList.length && $helpers.hasPermission(['view_invitation'])">
            <v-col cols="12" :class="activeMembersList.length ? 'mt-2' : 'mt-0'" class="px-0">
              <v-toolbar-title class="subtitle-1 grey-700--text font-weight-bold">Invitaciones pendientes</v-toolbar-title>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-row>
              <v-col v-for="(d, index) in invitationsList" :key="`${d.id}-${index}`" cols="5" :md="$vuetify.breakpoint.width < 1300 ? 4 : 3" xl="2">
                <v-hover v-slot="{ hover }">
                  <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat>
                    <v-row no-gutters>
                      <v-icon class="mb-1" color="blue-500">mdi-account</v-icon>
                      <v-spacer />
                      <v-menu v-model="menuInvitation[index]" close-on-click  offset-y left :nudge-bottom="0" :nudge-left="25" transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mt-n1 mr-n2" @click="menuInvitation[index]=true" text v-bind="attrs" v-on="on" :ripple="false">
                            <v-icon color="grey-700" size="24">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item class="px-3" v-if="$helpers.hasPermission(['add_invitation']) && validateDate(d)" @click="resendInvitations(d)" :ripple="false">
                            <v-list-item-title class="body-2">Reenviar invitación</v-list-item-title>
                          </v-list-item>
                          <v-list-item class="px-3" v-if="$helpers.hasPermission(['delete_invitation'])" @click="handlerDeleteInvitation(index)" :ripple="false">
                            <v-list-item-title class="body-2">Cancelar invitación</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                    <span class="d-block">
                      <v-sheet class="transparent" height="25">
                        <span v-if="d.name.length" class="grey-700--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 99%;">
                          <span class="text-capitalize">{{d.name | lower}}</span>
                        </span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </v-sheet>
                    </span>
                    <span v-if="d.email.length" class="mt-n2 d-block body-2 grey-300--text d-inline-block text-truncate" style="max-width: 99%; line-height: 22px;">{{d.email}}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    <v-col class="pa-0">
                      <div class="d-block d-flex mt-3 justify-space-between">
                        <div class="body-2 grey-300--text">
                          Enviado el:
                          <span class="d-block" v-if="d.sent">{{ d.sent | dateTime }}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </div>
                        <div class="body-2 grey-300--text ml-5">
                          {{ validateDate(d) ? 'Expirado' : 'Expira' }} el:
                          <span class="d-block" v-if="d.due_date">{{ d.due_date | dateTime }}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </div>
                      </div>
                    </v-col>
                  </v-card>
                </v-hover>
              </v-col>
              </v-row>
            </v-col>
            <VMainPagination class="mt-5" key="" :count="invitationsList?.length" :currentPage="currentPage" @isActive="setQueryInvitations($event)" />
          </template>
        </template>
        <!-- end pendients -->

        <!-- inactive -->
        <skeleton-list v-if="loadingInactiveMembers" />
        <template v-else-if="['Inactive', null].includes((($route || {}).query || {}).status || null)">
          <template v-if="inactiveMembersList.length && $helpers.hasPermission(['view_member'])">
            <v-col cols="12" class="my-2 px-0">
              <v-toolbar-title class="subtitle-1 grey-700--text font-weight-bold">Usuarios inactivos</v-toolbar-title>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-row>
                <v-col v-for="(d, index) in inactiveMembersList" :key="`${d.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                  <v-hover v-slot="{ hover }">
                    <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="menuInactiveMember[index]=true">
                      <v-row no-gutters>
                        <v-icon class="mb-1" color="blue-500">mdi-account</v-icon>
                        <v-spacer />
                        <v-menu v-model="menuInactiveMember[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="25" transition="slide-y-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                              <v-icon color="grey-700" size="24">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0">
                            <v-list-item class="px-3" v-if="$helpers.hasPermission(['change_member'])" :ripple="false" @click="deleteMember('inactive', index)">
                              <v-list-item-title class="body-2">Activar usuario</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <span class="d-block">
                        <v-sheet class="transparent" height="25">
                          <span v-if="d.first_name.length && d.first_name !== ' '" class="grey-700--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 99%;">
                            <span class="text-capitalize">{{d.first_name | lower}} {{d.last_name | lower}}</span>
                          </span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </v-sheet>
                      </span>
                      <span v-if="d.email.length" class="mt-n2 d-block body-2 grey-300--text d-inline-block text-truncate" style="max-width: 99%; line-height: 22px;">{{d.email}}</span>
                      <span class="d-block mt-n1 body-2 text--disabled" v-else>Sin especificar</span>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
            <div id="inactive" />
            <VMainPagination class="mt-5" key="Inactive" :count="inactiveMembersList?.length" :currentPage="currentPageInactive" @isActive="setQueryMembers($event, 'Inactive')" />
          </template>
        </template>
        <!-- end inactive -->
        <template v-if="!loadingInactiveMembers && !loadingInvitations && !loadingActiveMembers">
          <v-col class="px-0" cols="12" v-if="(!activeMembersList.length && !inactiveMembersList.length && !instanceInvitationsList.length)">
            <v-empty-state type="users" id="Usuarios" :isFree="true" title="No existen usuarios" customClass="mt-5" />
          </v-col>
        </template>
      </template>
    </template>
    <!-- dialog invitations -->
    <v-dialog v-model="dialogCreate" width="700" persistent no-click-animation overlay-color="grey-500">
      <v-card flat outlined rounded>
        <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Invitar personas a {{account.name}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogCreate=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pl-5 pr-0 py-4">
          <v-row align="center" no-gutters>
            <v-col cols="3" v-if="invitations.length === 1">
              <img :src="require(`@/assets/backgrounds/modal-member.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-0 pl-1">
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col class="pa-0" cols="7">
                    <span class="body-1 grey-500--text font-weight-medium">Email</span>
                  </v-col>
                  <v-col class="pa-0 pl-3" cols="4">
                    <span class="body-1 grey-500--text font-weight-medium">Nombre</span>
                  </v-col>
                </v-row>
                <v-row v-for="(d, i) in invitations" :key="i" no-gutters>
                  <v-col class="py-1 pl-0 pr-2" cols="7">
                    <v-text-field v-model.trim="$v.invitations.$each[i].email.$model" :error="$v.invitations.$each[i].email.$error" hide-details maxlength="254" outlined required single-line dense />
                    <template v-if="((invitations || [])[i] || {}).email.length">
                      <span class="red--text caption py-1" v-if="!$v.invitations.$each[i].email.isUser">Ya es usuario de la cuenta</span>
                      <span class="red--text caption py-1" v-if="!$v.invitations.$each[i].email.isInvited">Tiene una invitación pendiente</span>
                    </template>
                  </v-col>
                  <v-col class="py-1 pl-1 pr-0">
                    <v-text-field v-model.trim="$v.invitations.$each[i].name.$model" :error="$v.invitations.$each[i].name.$error" hide-details outlined single-line dense />
                  </v-col>
                  <div class="mr-3 pl-1" style="max-width:40px;">
                    <v-btn v-if="i >= 1 || invitations.length > 1" @click="invitations.splice(i, 1)" icon text color="grey-700" :ripple="false"><v-icon color="blue-500">mdi-delete</v-icon></v-btn>
                  </div>
                </v-row>
                <v-row no-gutters>
                  <v-col class="pa-0">
                    <v-btn class="mt-n1" v-if="invitations.length < 10" @click.prevent="addRowInvitations" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                      <span class="font-weight-medium body-1">Agregar otro</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="dialogCreate=false" outlined>Cancelar</v-btn>
          <v-btn @click="handleInvitations" :loading="createLoader" color="blue-500">Enviar invitaciones</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog invitations -->

    <!-- dialog deleteMember -->
    <v-dialog v-model="dialogDelete" width="600" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">{{ deletecurrentMember.is_active ? 'Desactivar' : 'Activar' }} usuario</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-500--text">
          <span class="d-block">¿Qué ocurre cuando se {{deletecurrentMember.is_active ? 'desactiva' : 'activa'}} un usuario?</span>
          <span class="d-block mt-4">1. El usuario {{deletecurrentMember.is_active ? 'ya no' : ''}} podrá trabajar en el espacio de trabajo.</span>
          <span class="d-block mt-2 mb-3">2. Toda la información creada por el usuario seguirá estando disponible.</span>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="deleteMember">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog deleteMember -->

    <!-- dialog invitation delete -->
    <v-dialog v-model="dialogDeleteInvitation" width="600" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Eliminar invitación</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDeleteInvitation=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-500--text">
          <span class="d-block">{{ (invitationsList[currentDeleteInvitationID] || []).email || '' }} no podrá unirse a {{account.name}}</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDeleteInvitation=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="deleteInvitation" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog invitation delete -->
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import cloneDeep from 'lodash/cloneDeep'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'

export default {
  components: { VMainPagination, VEmptyState, SkeletonList, PermissionDenied, VQueryBuilder },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    currentPageActive: 1,
    currentPageInactive: 1,
    currentPage: 1,
    queryFilters: null,
    currentFilterStatus: null,
    deletecurrentMember: {},
    menuActiveMember: [false],
    menuInactiveMember: [false],
    menuInvitation: [false],
    dialogDelete: false,
    currentDeleteInvitationID: null,
    dialogDeleteInvitation: false,
    confirm: '',
    panel: false,
    current: null,
    isActive: false,
    loadingActiveMembers: false,
    loadingInactiveMembers: false,
    loadingInvitations: false,
    invitations: [
      { email: '', name: '' }
    ],
    dialogCreate: false,
    createLoader: false,
    activeMembersList: [],
    activeMembersCount: 0,
    inactiveMembersList: [],
    inactiveMembersCount: 0,
    instanceInvitationsList: [],
    filters: [
      {
        type: 'text',
        id: 'first_name',
        label: 'Nombre',
        value: null,
        category: 'd',
        title: 'Información del usuario',
        operators: ['']
      },
      {
        type: 'text',
        id: 'email',
        label: 'Email',
        value: null,
        category: 'd',
        title: 'Información del usuario',
        operators: ['']
      },
      {
        type: 'select',
        id: 'status',
        label: 'Estado',
        category: 'e',
        valueType: '',
        value: null,
        title: 'Estado',
        choices: [
          {label: 'Activo', value: 'Active'},
          {label: 'Inactivo', value: 'Inactive'},
          {label: 'Pendiente', value: 'Accepted'}
        ]
      }
    ]
  }),
  computed: {
    ...mapState({
      membersList: state => cloneDeep(state.accounts.membersList),
      count: state => cloneDeep(state.accounts.membersCount),
      invitationsList: state => state.accounts.invitationsList,
      invitationsCount: state => cloneDeep(state.accounts.invitationsCount)
    }),
    validateDate () {
      return (item = {}) => {
        return moment().isAfter(item.due_date)
      }
    }
  },
  watch: {
    dialogCreate () {
      if (this.dialogCreate) {
        return false
      }
      this.$v.invitations.$reset()
    },
    '$route.query': {
      handler (val) {
        if (val) {
          this.activeMembersList = []
          this.inactiveMembersList = []
          this.instanceInvitationsList = []
          this.currentFilterStatus = val?.status ?? null
          if (['Active', 'Inactive'].includes(val.status)) {
            this.getMembers(val.status)
          } else if (val.status === 'Accepted') {
            this.getInvitations()
          } else {
            this.getListMembers()
          }
        }
      }
    }
  },
  created () {
    if (['Active', 'Inactive'].includes(this.$route.query.status)) {
      this.getMembers(this.$route.query.status)
    } else if (this.$route.query.status === 'Accepted') {
      this.getInvitations()
    } else {
      this.getListMembers()
    }
  },
  methods: {
    setQueryMembers (pag, key = '') {
      this[`currentPage${key}`] = parseInt(pag)
      this.getMembers(key, pag)
    },
    setQueryInvitations (pag) {
      this.currentPage = parseInt(pag)
      this.getInvitations(pag)
    },
    getListMembers () {
      this.getMembers('Inactive')
      this.getMembers('Active')
      this.getInvitations()
    },
    handleUpdate () {
      this.panel = false
      this.getListMembers()
    },
    getMembers (key = '', pag) {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (query?.status) delete query.status
      if (this.$helpers.hasPermission(['view_member'])) {
        this[`loading${key}Members`] = true
        this.$store.dispatch('accounts/LIST', {
          resource: 'members',
          query: {
            ...query,
            ...(!!pag && { page: pag }),
            is_active: this.$route?.query?.status === 'Active' || key === 'Active' ? 'true' : 'false'
          }
        })
        .then((response) => {
          const { next, results } = response.data
          this[`${key.toLowerCase()}MembersList`] = results
          this[`${key.toLowerCase()}MembersCount`] = !!next
        })
        .finally(() => {
          this[`loading${key}Members`] = false
        })
      }
    },
    getInvitations (pag) {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (query?.first_name) delete query?.first_name
      if (query?.status) delete query.status

      query = {...query, ...(this.$route.query.first_name && { name: this.$route.query.first_name}) }
      if (this.$helpers.hasPermission(['view_invitation'])) {
        this.loadingInvitations = true
        this.$store.dispatch('accounts/LIST', {
          resource: 'invitations',
          query: {
            ...query,
            ...(!!pag && { page: pag }),
            accepted: this.$route.query.status ? 'True' : 'False'
          }
        })
        .then((response) => {
          this.instanceInvitationsList = [...response.data.results]
          this.instanceInvitationsList.forEach((item) => {
            item.due_date = moment(item.sent).add(3, 'days')
          })
        })
        .finally(() => {
          this.loadingInvitations = false
        })
      }
    },
    resendInvitations (invitation = {}) {
      this.invitations = [{ email: invitation.email, name: invitation.name }]
      this.postInvitations()
    },
    handleInvitations () {
      this.$v.invitations.$touch()
      if (this.$v.invitations.$invalid) {
        return false
      }
      this.postInvitations()
    },
    postInvitations () {
      this.createLoader = true
      this.$store.dispatch('accounts/BULK_INVITATION', {
        persons: this.invitations
      })
      .then(() => {
        this.invitations = [
          { email: '', name: '' }
        ]
        this.$v.invitations.$reset()
        this.getInvitations()
        this.$dialog.message.info('Las invitaciones han sido enviadas')
      })
      .catch((error) => {
        if (error.toString().search('400') !== -1) {
          this.dialogCreate = false
        }
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.createLoader = false
        this.dialogCreate = false
      })
    },
    patchMember (key = '', i = {}) {
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'members',
        payload: {
          is_admin: !this[`${key}MembersList`][i].is_admin
        },
        id: this[`${key}MembersList`][i].id
      })
      .then(() => {
        this.$store.dispatch('accounts/LIST', {
          resource: 'members',
          loader: false,
          query: {
            is_active: true
          }
        })
        .then((response) => {
          const { count, results } = response.data
          this.activeMembersList = results
          this.activeMembersCount = count
        })
        this.$dialog.message.info('El tipo de cuenta del usuario se ha actualizado')
      })
    },
    handlerDeleteMember (key = '', i = {}) {
      this.dialogDelete = true
      this.deletecurrentMember = this[`${key}MembersList`][i]
    },
    deleteMember (key = '', i = {}) {
      if (key === 'inactive') this.deletecurrentMember = this[`${key}MembersList`][i]
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'members',
        payload: {
          is_active: !this.deletecurrentMember.is_active
        },
        id: this.deletecurrentMember.id
      })
      .then(() => {
        this.getListMembers()

        this.$dialog.message.info(`El usuario ha sido ${this.deletecurrentMember.is_active ? 'desactivado' : 'activado'} en la organización`)
      })
      .finally(() => {
        this.dialogDelete = false
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    handlerDeleteInvitation (i = '') {
      this.dialogDeleteInvitation = true
      this.currentDeleteInvitationID = i
    },
    deleteInvitation () {
      this.$store.dispatch('accounts/DESTROY', {
        resource: 'invitations',
        id: this.invitationsList[this.currentDeleteInvitationID].id
      })
      .then(() => {
        this.getInvitations()
        this.$dialog.message.info('La invitación ha sido eliminada')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data.detail))
      })
      .finally(() => {
        this.confirm = ''
        this.dialogDeleteInvitation = false
      })
    },
    addRowInvitations () {
      this.invitations.push({
        email: '',
        name: ''
      })
    },
    createExport (key = '') {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (query?.status) delete query.status
      if (key === 'invitations') {
        if (query?.first_name) delete query?.first_name
        query = {...query, ...(this.$route.query.status === 'Accepted' && { accepted: this.$route.query.status === 'Accepted' ? 'False' : 'True' }), ...(this.$route.query.first_name && { name: this.$route.query.first_name}) }
      } else {
        query = {...query, ...(['Active', 'Inactive'].includes(this.$route.query.status) && { is_active: this.$route.query.status === 'Active' }) }
      }

      this.$store.dispatch('batch/LIST', {
        resource: `${key}/export_to_csv`,
        query: query,
        loader: false,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const content = Buffer.from(response.data, 'base64')
        saveAs(new Blob([content], {type: 'application/csv'}), `Listado de ${key === 'members' ? 'Usuarios' : 'invitaciones'}.csv`)
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch((response) => {
        this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
      .finally(() => {
        this.dialogExport = false
      })
    }
  },
  validations: {
    invitations: {
      $each: {
        email: {
          required,
          email,
          maxLength: maxLength(254),
          isUser: function (value) {
            return !this.membersList.filter(member => member.email === value).length
          },
          isInvited: function (value) {
            return !this.invitationsList.filter(invitation => invitation.email === value).length
          }
        },
        name: {
          required,
          maxLength: maxLength(32)
        }
      }
    }
  }
}
</script>